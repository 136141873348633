import styles from "./livePaymentTracking.scss";
import React, { useState, useEffect } from "react";
import momentjs from "moment-timezone";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import countryCodes from "../../countryCodes";
import Autocomplete from "@mui/material/Autocomplete";
import { fetchAllTransactions } from "../../actions/general";
import ViewPaymentPacket from "./ViewPaymentPacket/ViewPaymentPacket";

const LivePaymentTracking = ({ props }) => {
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("Mobile");
  const [ccod, setCcod] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [razorpayId, setRazorpayId] = useState("");
  const [paytmId, setPaytmId] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [imarticusId, setImarticusId] = useState("");
  const [payments, setPayments] = useState([]);
  const [warningText, setWarningText] = useState("");
  const [paymentsFetched, setPaymentsFetched] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [showPaymentPacketModal, setShowPaymentPacketModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    let searchVal;
    if (!searchType) {
      return alert("Please select a search type");
    }
    if (searchType == "Mobile") {
      if (!mobile) {
        return alert("Please enter correct details");
      }
      searchVal = `+${ccod.value}${mobile}`;
    } else if (searchType == "Email") {
      searchVal = email;
    } else if (searchType == "RazorPay ID") {
      searchVal = razorpayId;
    } else if (searchType == "Paytm ID") {
      searchVal = paytmId;
    } else if (searchType == "Stripe ID") {
      searchVal = stripeId;
    } else if (searchType == "Imarticus ID") {
      searchVal = imarticusId;
    }
    if (!searchVal) {
      return alert("Please select a search type");
    }
    setLoading(true);
    let { success, message, ...data } = await fetchAllTransactions({
      search_type: searchType,
      search_val: searchVal,
      page: newPage,
      rowsPerPage,
    });
    setPaymentsFetched(true);
    if (!success) {
      if (message) {
        window.alert(message);
        setWarningText(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({
      allTransactions: data.allTransactions,
      allTransactionsCount: data.allTransactionsCount,
    });
    setTotalPayments(data.allTransactionsCount);
    setPayments(data.allTransactions);
    setLoading(false);
  };
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let searchVal;
    if (!searchType) {
      return alert("Please select a search type");
    }
    if (searchType == "Mobile") {
      if (!mobile) {
        return alert("Please enter correct details");
      }
      searchVal = `+${ccod.value}${mobile}`;
    } else if (searchType == "Email") {
      searchVal = email;
    } else if (searchType == "RazorPay ID") {
      searchVal = razorpayId;
    } else if (searchType == "Paytm ID") {
      searchVal = paytmId;
    } else if (searchType == "Stripe ID") {
      searchVal = stripeId;
    } else if (searchType == "Imarticus ID") {
      searchVal = imarticusId;
    }
    if (!searchVal) {
      return alert("Please select a search type");
    }
    setLoading(true);
    let { success, message, ...data } = await fetchAllTransactions({
      search_type: searchType,
      search_val: searchVal,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    setPaymentsFetched(true);
    if (!success) {
      if (message) {
        window.alert(message);
        setWarningText(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({
      allTransactions: data.allTransactions,
      allTransactionsCount: data.allTransactionsCount,
    });
    setTotalPayments(data.allTransactionsCount);
    setPayments(data.allTransactions);
    setLoading(false);
  };

  let selectCcod = (ev, ccod) => {
    console.log({ ev, ccod });
    setCcod({ ...ccod });
  };

  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };

  const fetchPayments = async () => {
    let searchVal;
    if (!searchType) {
      return alert("Please select a search type");
    }
    if (searchType == "Mobile") {
      if (!mobile) {
        return alert("Please enter correct details");
      }
      searchVal = `+${ccod.value}${mobile}`;
    } else if (searchType == "Email") {
      searchVal = email;
    } else if (searchType == "RazorPay ID") {
      searchVal = razorpayId;
    } else if (searchType == "Paytm ID") {
      searchVal = paytmId;
    } else if (searchType == "Stripe ID") {
      searchVal = stripeId;
    } else if (searchType == "Imarticus ID") {
      searchVal = imarticusId;
    }
    if (!searchVal) {
      return alert("Please select a search type");
    }
    setPage(0);
    setRowsPerPage(25);
    setLoading(true);
    let { success, message, ...data } = await fetchAllTransactions({
      search_type: searchType,
      search_val: searchVal,
      page: 0,
      rowsPerPage: 25,
    });
    setPaymentsFetched(true);
    if (!success) {
      if (message) {
        window.alert(message);
        setWarningText(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({
      allTransactions: data.allTransactions,
      allTransactionsCount: data.allTransactionsCount,
    });
    setTotalPayments(data.allTransactionsCount);
    setPayments(data.allTransactions);
    setLoading(false);
  };

  const closeShowPaymentPacketModal = () => {
    setShowPaymentPacketModal(false);
  };

  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="mainContainer">
      {showPaymentPacketModal && (
        <ViewPaymentPacket
          open={showPaymentPacketModal}
          closeModal={closeShowPaymentPacketModal}
          paymentId={selectedPayment._id}
          gateway={selectedPayment.gateway}
        />
      )}
      <h1>Live Payment Tracking</h1>
      <span>Please provide the payment details:</span>
      <div className="row">
        <Autocomplete
          multiple={false}
          disablePortal
          disableClearable
          sx={{ width: 150 }}
          autoHighlight
          value={searchType}
          onChange={(ev, type) => {
            setCcod({
              code: "IN",
              value: "91",
              display: "India (+91)",
            });
            setMobile("");
            setEmail("");
            setRazorpayId("");
            setPaytmId("");
            setStripeId("");
            setImarticusId("");
            setPaymentsFetched(false);
            setPayments([]);
            setSearchType(type);
          }}
          getOptionLabel={(type) => {
            return type || "";
          }}
          id="searchType"
          options={[
            "Mobile",
            "Email",
            "RazorPay ID",
            "Paytm ID",
            "Stripe ID",
            "Imarticus ID",
          ]}
          renderInput={(params) => {
            return (
              <TextField label="Search type" {...params} variant="outlined" />
            );
          }}
        />
        {searchType == "Mobile" && (
          <>
            <Autocomplete
              multiple={false}
              disablePortal
              disableClearable
              sx={{ width: 150 }}
              autoHighlight
              value={ccod}
              onChange={selectCcod}
              getOptionLabel={(ccode) => {
                return ccode?.display || "";
              }}
              id="ccodeSelector"
              options={countryCodes}
              renderInput={(params) => {
                return (
                  <TextField
                    label="Select a country code"
                    {...params}
                    variant="outlined"
                  />
                );
              }}
            />
            <TextField
              id="mobile"
              value={mobile}
              onChange={(e) =>
                e.target.value.length < 15 ? setMobile(e.target.value) : null
              }
              label="Mobile Number"
              variant="outlined"
            />
          </>
        )}
        {searchType == "Email" && (
          <>
            <TextField
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              variant="outlined"
            />
          </>
        )}
        {searchType == "RazorPay ID" && (
          <>
            <TextField
              id="razorpaytId"
              value={razorpayId}
              onChange={(e) => setRazorpayId(e.target.value)}
              label="Razorpay ID"
              variant="outlined"
              placeholder="pay_KbCEDHh1IrU4RJ"
            />
          </>
        )}
        {searchType == "Paytm ID" && (
          <>
            <TextField
              id="paytmId"
              value={paytmId}
              onChange={(e) => setPaytmId(e.target.value)}
              label="Paytm ID"
              variant="outlined"
              placeholder="20210201111212800110168984702314159"
            />
          </>
        )}
        {searchType == "Stripe ID" && (
          <>
            <TextField
              id="stripeId"
              value={stripeId}
              onChange={(e) => setStripeId(e.target.value)}
              label="Stripe ID"
              variant="outlined"
              placeholder="pi_3QLhbcSBhqVGsKD91z2NqFXU"
            />
          </>
        )}
        {searchType == "Imarticus ID" && (
          <>
            <TextField
              id="imarticusId"
              value={imarticusId}
              onChange={(e) => setImarticusId(e.target.value)}
              label="Imarticus ID"
              variant="outlined"
              placeholder="ILP1124566C"
            />
          </>
        )}
      </div>
      {warningText && <span className="warning">{warningText}</span>}
      <Button variant="outlined" color="info" onClick={fetchPayments}>
        Fetch Payments
      </Button>

      {/* <div>
        *Note: Email and Mobile can only be used to track Razorpay Payments.
      </div> */}

      {paymentsFetched ? (
        <div style={{ width: "fit-content", minWidth: "800px" }}>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div className="pastPayment">Payments</div>
          </div>
          {payments.length ? (
            <TableContainer className="mainTable" component={Paper}>
              <Table aria-label="custom pagination table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ fontWeight: 600, width: "100px" }}
                    >
                      S. No.
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ fontWeight: 600, width: "100px" }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ fontWeight: 600, width: "100px" }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: 600, width: "200px" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: 600, width: "200px" }}
                    >
                      Payment Gateway ID
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: 600, width: "200px" }}
                    >
                      Imarticus ID
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: 600, width: "200px" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ fontWeight: 600, width: "200px" }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((row, index) =>
                    row.gateway == 1 || row.gateway == 3 ? (
                      <TableRow
                        key={index}
                        className="paymentrow"
                        onClick={() => {
                          setSelectedPayment(row);
                          setShowPaymentPacketModal(true);
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {row.razorpayPacket.email}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {row.razorpayPacket.contact}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {getDate(row.razorpayPacket.created_at * 1000)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.razorpayPacket.id}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.razorpayPacket.notes
                            ? row.gateway == 1
                              ? row.razorpayPacket.notes.order_id
                              : row.razorpayPacket.notes.transactionPaymentId ||
                                row.razorpayPacket.notes.trxn_id ||
                                "---"
                            : "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.razorpayPacket.currency}{" "}
                          {row.razorpayPacket.amount / 100}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={
                            row.razorpayPacket.status == "captured"
                              ? "done"
                              : row.razorpayPacket.status == "failed"
                              ? "failed"
                              : row.razorpayPacket.status == "created"
                              ? "created"
                              : row.razorpayPacket.status == "authorized"
                              ? "authorized"
                              : "refunded"
                          }
                          style={{ width: "200px" }}
                        >
                          {row.razorpayPacket.status == "captured"
                            ? "Done"
                            : row.razorpayPacket.status == "failed"
                            ? "Failed"
                            : row.razorpayPacket.status == "created"
                            ? "Created"
                            : row.razorpayPacket.status == "authorized"
                            ? "Authorized"
                            : "Refunded"}
                        </TableCell>
                      </TableRow>
                    ) : row.gateway == 2 ? (
                      <TableRow
                        key={index}
                        className="paymentrow"
                        onClick={() => {
                          setSelectedPayment(row);
                          setShowPaymentPacketModal(true);
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {row.paytmPacket.email || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {row.paytmPacket.contact || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {getDate(row.paytmPacket.orderCreatedTime)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.paytmPacket.txnId || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.paytmPacket.merchantOrderId || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          INR {row.paytmPacket.amount}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={
                            row.paytmPacket.orderSearchStatus == "SUCCESS"
                              ? "done"
                              : row.paytmPacket.orderSearchStatus == "FAILURE"
                              ? "failed"
                              : "pending"
                          }
                          style={{ width: "200px" }}
                        >
                          {row.paytmPacket.orderSearchStatus == "SUCCESS"
                            ? "Done"
                            : row.paytmPacket.orderSearchStatus == "FAILURE"
                            ? "Failed"
                            : "Pending"}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        key={index}
                        className="paymentrow"
                        onClick={() => {
                          setSelectedPayment(row);
                          setShowPaymentPacketModal(true);
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {row.stripePacket.email || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ width: "10px" }}
                        >
                          {row.stripePacket.contact || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {getDate(row.stripePacket.created * 1000)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.stripePacket.id || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.stripePacket.description || "---"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "200px" }}
                        >
                          {row.stripePacket.currency.toUpperCase()}{" "}
                          {row.stripePacket.amount}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={
                            row.stripePacket.status == "succeeded"
                              ? "done"
                              : row.stripePacket.status == "canceled"
                              ? "failed"
                              : "pending"
                          }
                          style={{ width: "200px" }}
                        >
                          {row.stripePacket.status == "succeeded"
                            ? "Done"
                            : row.stripePacket.status == "canceled"
                            ? "Failed"
                            : "Pending"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalPayments}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                colSpan={8}
              />
            </TableContainer>
          ) : (
            <div>No Payments found</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LivePaymentTracking;
