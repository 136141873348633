export default function reducer(
  state = {
    data: {},
  },
  action
) {
  switch (action.type) {
    case "LOGIN_USER_REJECTED": {
      console.log(action);

      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid login credentials",
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "VERIFY_OTP_FULFILLED": {
      return {
        data: {
          type: "success",
          title: "Success",
          message: "Successfully logged in",
        },
      };
    }
    case "VERIFY_OTP_REJECTED": {
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid Otp",
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "FETCH_GROUP_DETAILS_REJECTED": {
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid Group code",
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "CREATE_PROMO_OTP_SENT_SUCCESS": {
      return {
        data: {
          type: "success",
          title: "Success",
          message: "Successfully sent OTP",
        },
      };
    }
    case "CREATE_PROMO_OTP_SENT_FAILED": {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 5700
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Account Not Found",
          },
        };
      }
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 79000
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Oops, account is not verified",
          },
        };
      }
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 56000
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "course not found",
          },
        };
      }
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Invalid details",
          },
        };
      }
      if (action.payload.response && action.payload.response.status == 449) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: action.payload.response.data.message,
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Failed to sent OTP",
        },
      };
    }
    case "CREATE_SPECIAL_PROMO_FULFILLED": {
      return {
        data: {
          type: "success",
          title: "Success",
          message: "Successfully created special promo code",
        },
      };
    }
    case "CREATE_PROMO_FULFILLED": {
      return {
        data: {
          type: "success",
          title: "Success",
          message: "Successfully created promo code",
        },
      };
    }
    case "CREATE_PROMO_REJECTED": {
      console.log({ action });
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 5700
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Account Not Found",
          },
        };
      }
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 73000
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Promo expiry date is invalid",
          },
        };
      }
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 79000
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Oops, account is not verified",
          },
        };
      }
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.code == 56000
      ) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "course not found",
          },
        };
      }
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: "Invalid details",
          },
        };
      }
      if (action.payload.response && action.payload.response.status == 449) {
        return {
          data: {
            type: "error",
            title: "Error creating promo code",
            message: action.payload.response.data.message,
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error creating promo code",
          message: "Please try again later",
        },
      };
    }
    case "EDIT_PROMO_FULFILLED": {
      if (
        (action.payload &&
          action.payload.data &&
          action.payload.data.status == true) ||
        (action.payload && !action.payload.data)
      ) {
        return {
          data: {
            type: "success",
            title: "Success",
            message: "Successfully edited promo code",
          },
        };
      } else if (
        action.payload &&
        action.payload.data &&
        action.payload.data.status == false
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: action.payload.data.msg,
          },
        };
      }
    }
    case "EDIT_PROMO_REJECTED": {
      console.log(action, "+++");
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.code == 75000
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Oops, This promo code does not exists",
          },
        };
      }
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.code == 79000
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Oops, account is not verified",
          },
        };
      }
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.code == 56000
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "course not found",
          },
        };
      }
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.code == 73000
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Promo expiry date is invalid",
          },
        };
      }
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid details",
          },
        };
      }
      if (action.payload.response && action.payload.response.status == 449) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: action.payload.response.data.message,
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "DELETE_PROMOCODE_FULFILLED": {
      if (action.status == true) {
        return {
          data: {
            type: "success",
            title: "Success",
            message: action.msg,
          },
        };
      } else {
        return {
          data: {
            type: "error",
            title: "Error",
            message: action.msg,
          },
        };
      }
    }
    case "DELETE_PROMOCODE_REJECTED": {
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid promo details",
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "LIST_PROMO_REJECTED": {
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid group code",
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "LIST_SPECIALPROMO_REJECTED": {
      if (action.payload.response && action.payload.response.status == 400) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Invalid phone number",
          },
        };
      }
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.code == 500
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: "Please try again later",
          },
        };
      }
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        return {
          data: {
            type: "error",
            title: "Error",
            message: action.payload.response.data.message,
          },
        };
      }
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "FETCH_PROMO_REJECTED": {
      return {
        data: {
          type: "error",
          title: "Error",
          message: "Please try again later",
        },
      };
    }
    case "CLEAR_NOTIFICATIONS": {
      return { ...state, data: {} };
    }
  }

  return state;
}
