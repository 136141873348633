import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import momentjs from "moment-timezone";
import { Button, Col, Modal, Row } from "antd";
import Loader from "./Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { fetchBulkImportJobs } from "../../actions/applicationFormAction";
import currency from "../../currency";

const PastBulkUploadRequestModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [requests, setRequests] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [showRequestData, setShowRequestData] = useState(false);
  const [requestSelected, setRequestSelected] = useState();

  useEffect(() => {
    setPackages(props.appreview.crs_pkgs);
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setLoading(true);
    const res = await fetchBulkImportJobs({
      crs_pg_id: props.appreview.crs_pg_id._id,
      page_size: rowsPerPage,
      page_no: page,
    });

    console.log({ res });
    setRequests(res.jobs);
    setTotalRequests(res.totalCount);
    setLoading(false);
  };

  const closeModal = () => {
    props.handleViewBulkUploadPastRequestModalToggle(false);
  };

  const handleChangePage = async (event, newPage) => {
    setLoading(true);
    setPage(newPage);
    const { success, message, ...res } = await fetchBulkImportJobs({
      crs_pg_id: props.appreview.crs_pg_id._id,
      page_size: rowsPerPage,
      page_no: newPage,
    });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({ res });
    setRequests(res.jobs);
    setTotalRequests(res.totalCount);
    setLoading(false);
  };

  const handleChangeRowsPerPage = async (event) => {
    setLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    const { success, message, ...res } = await fetchBulkImportJobs({
      crs_pg_id: props.appreview.crs_pg_id._id,
      page_size: parseInt(event.target.value, 10),
      page_no: 0,
    });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({ res });
    setRequests(res.jobs);
    setTotalRequests(res.totalCount);
    setLoading(false);
  };

  const getCurrency = (id) => {
    const pkg = packages.find((pkg) => pkg._id == id);
    return pkg.curr;
  };

  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };

  return loading ? (
    <Loader loading />
  ) : (
    <div>
      <Modal
        title="Past Bulk Upload Requests"
        open={props.open}
        onCancel={closeModal}
        width="97vw"
        className="applicationmodal"
      >
        <div className="row no-margin" style={{ padding: 24 }}>
          {showRequestData ? (
            <>
              <TableContainer className="mainTable" component={Paper}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "20px" }}
                      >
                        S. No.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Mobile Number
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Import Status
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Failure Reason (If Failed)
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        UID
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requests[requestSelected].request_data.map(
                      (row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row["Name"]}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            +{row["Country Code"]}- {row["Mobile"]}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row["Email"]}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row["Import Status"] || "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row["Failure Reason"] || "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row["UID"] || "-"}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  width: "100%",
                  height: "28px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setShowRequestData(false);
                    setRequestSelected();
                  }}
                >
                  Back
                </Button>
              </div>
            </>
          ) : requests && requests.length > 0 ? (
            <>
              <TableContainer className="mainTable" component={Paper}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "20px" }}
                      >
                        S. No.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Currency
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        No Of Learners
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Comment
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Scholarship Applied
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Promocode Applied
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Registration Fees
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Application Fees
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Reason (If Rejected)
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Requested By
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Approved By
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ fontWeight: 600, width: "150px" }}
                      >
                        Learners List
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requests.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {getCurrency(row.package_id)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.request_data.length}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.comment}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {getDate(row.createdAt)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.scholarship
                            ? `${currency[getCurrency(row.package_id)].symbol}${
                                row.scholarship
                              }`
                            : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.promocode ? row.promocode : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.registration
                            ? `${currency[getCurrency(row.package_id)].symbol}${
                                row.registration
                              }`
                            : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.application
                            ? `${currency[getCurrency(row.package_id)].symbol}${
                                row.application
                              }`
                            : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.job_status == 2
                            ? "Rejected"
                            : row.job_status == 1
                            ? "Pending"
                            : row.job_status == 3
                            ? "Approved"
                            : "Imported"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.error || "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.requester_email || "-"}
                        </TableCell>{" "}
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: "150px" }}
                        >
                          {row.approver_email || "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{
                            width: "150px",
                            textDecoration: "underline",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowRequestData(true);
                            setRequestSelected(index);
                          }}
                        >
                          View
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalRequests}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  colSpan={8}
                />
              </TableContainer>
            </>
          ) : (
            "No Past Requests Found"
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PastBulkUploadRequestModal);
