import React, { Component, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "antd";
import { TextField, La } from "@mui/material";
import Loader from "./Loader";
import currency from "../../currency";
import { Select } from "antd";
import {
  bulkUploadLearners,
  resendBulkUploadLearners,
  verifyBulkUploadLearners,
  fetchApplications,
} from "../../actions/applicationFormAction";
import csv from "../../assests/BulkLearnerImportSampleFile.csv";

const validCsvFormats = ["application/vnd.ms-excel", "text/csv", "csv"];

const BulkUploadLearnersModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [scholarship, setScholarship] = useState("");
  const [code, setCode] = useState("");
  const [registration, setRegistration] = useState("");
  const [application, setApplication] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [job_id, setJob_id] = useState("");
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const Ref = useRef(null);

  useEffect(() => {
    setPackages(props.appreview.crs_pkgs);
  }, []);

  const closeModal = () => {
    props.handleViewBulkUploadLearnersModalToggle(false);
  };

  const reset = () => {
    let id = "uploadList";
    let query = "#" + id;
    document.querySelector(query).value = null;
    setError("");
    setFile(null);
    setComment("");
    setScholarship("");
    setCode("");
    setRegistration("");
    setApplication("");
    setOtp("");
    setOtpSent(false);
    setJob_id("");
    setShowResendOtp(false);
    setTimer("00:00");
  };

  const setFileValue = (e, key) => {
    console.log({ event: e });
    let id = "uploadList";
    let query = "#" + id;
    let fileInput = document.querySelector(query).files[0];
    console.log({ fileInput });

    if (validCsvFormats.indexOf(fileInput.type) == -1) {
      setError("Please upload a valid CSV file.");
      e.target.value = null;
      return;
    }
    setFile(fileInput);
  };

  const submitRequest = async () => {
    let err = "";
    if (!file) {
      err += "Please upload an Input File.";
    }
    let tempComment = comment.trim();
    if (!tempComment) {
      err && (err += "/");
      err += "Please enter a comment.";
    }
    if (!scholarship && !code && !registration && !application) {
      err && (err += "/");
      err += "Please provide atleast 1 parameter to apply.";
    }
    if (err) {
      setError(err);
      return;
    }
    setError("");
    let data = {
      pkg_id: packages[selectedPackage]._id,
      crs_pg_id: props.appreview.crs_pg_id._id,
      file,
      comment,
    };
    if (scholarship) {
      data["scholarship"] = scholarship;
    }
    if (code) {
      data["code"] = code;
    }
    if (registration) {
      data["registration"] = registration;
    }
    if (application) {
      data["application"] = application;
    }
    setLoading(true);
    const { success, message, status, ...res } = await bulkUploadLearners(data);
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        setError(message);
      } else {
        setError("Something went wrong. Please try again.");
      }
      return;
    }
    setShowResendOtp(false);
    clearTimer(getDeadTime());
    setJob_id(res.job_id);
    setOtpSent(true);
    setLoading(false);
  };

  const resendRequest = async () => {
    setLoading(true);
    const { success, message, status, ...res } = await resendBulkUploadLearners(
      {
        job_id,
      }
    );
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        setError(message);
      } else {
        setError("Something went wrong. Please try again.");
      }
      return;
    }
    setShowResendOtp(false);
    clearTimer(getDeadTime());
    setOtpSent(true);
    setLoading(false);
  };

  const verifyRequest = async () => {
    if (!otp) {
      return setError("Please enter an otp.");
    }
    setLoading(true);
    const { success, message, status, ...res } = await verifyBulkUploadLearners(
      {
        job_id,
        otp_code: otp,
      }
    );
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        setError(message);
      } else {
        setError("Something went wrong. Please try again.");
      }
      return;
    }
    closeModal();
    props.dispatch(
      props.fetchApplications({
        crs_pg_id: props.appreview.crs_pg_id._id,
        app_status: undefined,
        appcurrent_page: 1,
      })
    );
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setShowResendOtp(true);
      if (Ref.current) clearInterval(Ref.current);
    }
  };

  const clearTimer = (e) => {
    setTimer("2:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  return loading ? (
    <Loader loading />
  ) : (
    <div>
      <Modal
        title="Bulk Upload Learners"
        open={props.open}
        onCancel={closeModal}
        width="700px"
        className="applicationmodal"
      >
        <div style={{ padding: 24 }}>
          {props.appreview.courseCategoryData &&
          props.appreview.courseCategoryData.admin_email ? (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "40px",
                }}
              >
                <Button
                  style={{ borderRadius: "8px" }}
                  href={csv}
                  target="_blank"
                >
                  Download Sample File
                </Button>
                <Select
                  onChange={(value) => {
                    setSelectedPackage(value);
                  }}
                  value={selectedPackage}
                  style={{ width: "60px" }}
                  className="app-dropdown"
                  disabled={otpSent}
                  InputProps={{
                    style: {
                      height: "40px",
                    },
                  }}
                >
                  {packages &&
                    packages.length &&
                    packages.map((crs_pkg, index) => (
                      <Option key={index} value={index}>
                        {currency[crs_pkg.curr].symbol}
                      </Option>
                    ))}
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      width: "148px",
                      textAlign: "left",
                    }}
                  >
                    Scholarship :{" "}
                  </div>
                  <TextField
                    disabled={otpSent}
                    type="number"
                    value={scholarship}
                    onChange={(e) => setScholarship(e.target.value)}
                    InputProps={{
                      style: {
                        height: "48px",
                        width: "240px",
                      },
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      width: "148px",
                      textAlign: "left",
                    }}
                  >
                    Promocode :{" "}
                  </div>
                  <TextField
                    disabled={otpSent}
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value.trim())}
                    InputProps={{
                      style: {
                        height: "48px",
                        width: "240px",
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      width: "148px",
                      textAlign: "left",
                    }}
                  >
                    Registration Fees :{" "}
                  </div>
                  <TextField
                    disabled={otpSent}
                    type="number"
                    value={registration}
                    onChange={(e) => setRegistration(e.target.value)}
                    InputProps={{
                      style: {
                        height: "48px",
                        width: "240px",
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      width: "148px",
                      textAlign: "left",
                    }}
                  >
                    Application Fees :{" "}
                  </div>
                  <TextField
                    disabled={otpSent}
                    type="number"
                    value={application}
                    onChange={(e) => setApplication(e.target.value)}
                    InputProps={{
                      style: {
                        height: "48px",
                        width: "240px",
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      width: "148px",
                      textAlign: "left",
                    }}
                  >
                    Comment <span style={{ color: "red" }}>*</span> :{" "}
                  </div>
                  <TextField
                    disabled={otpSent}
                    onChange={(e) => setComment(e.target.value)}
                    type="text"
                    value={comment}
                    InputProps={{
                      style: {
                        height: "48px",
                        width: "240px",
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "20px",
                      width: "148px",
                      textAlign: "left",
                    }}
                  >
                    Input File <span style={{ color: "red" }}>*</span> :{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      disabled={otpSent}
                      type="file"
                      accept=".csv"
                      onChange={(e) => {
                        setFileValue(e);
                      }}
                      id="uploadList"
                      InputProps={{
                        style: {
                          height: "48px",
                          width: "240px",
                        },
                      }}
                    />
                    {file && (
                      <div
                        style={{
                          marginLeft: "12px",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        {file.name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "24px",
                  fontSize: "18px",
                  marginBottom: "24px",
                }}
              >
                <soan style={{ fontWeight: "600" }}>Admin</soan> :{" "}
                {props.appreview.courseCategoryData.admin_name} (
                {props.appreview.courseCategoryData.admin_email})
              </div>
              <div style={{ fontSize: "18px" }}>
                Note: If any parameter needs to remain unchanged, leave it's
                input field empty.
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {otpSent && (
                  <>
                    <TextField
                      type="number"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      style={{ marginTop: "32px" }}
                      placeholder="Enter OTP"
                      InputProps={{
                        style: {
                          height: "40px",
                          width: "128px",
                        },
                      }}
                    />
                    {!showResendOtp ? (
                      <div
                        style={{
                          marginLeft: "20px",
                          marginTop: "44px",
                          color: "#055646",
                        }}
                      >
                        Resend OTP in {timer}
                      </div>
                    ) : (
                      <button
                        style={{
                          marginLeft: "20px",
                          width: "166px",
                          color: "white",
                          backgroundColor: "#055646",
                          borderRadius: "4px",
                          padding: "10px 36px",
                          textAlign: "center",
                          marginTop: "32px",
                        }}
                        onClick={resendRequest}
                      >
                        Resend OTP
                      </button>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                {!otpSent ? (
                  <button
                    style={{
                      width: "166px",
                      color: "white",
                      backgroundColor: "#055646",
                      borderRadius: "4px",
                      padding: "10px 36px",
                      textAlign: "center",
                      marginTop: "32px",
                    }}
                    onClick={submitRequest}
                  >
                    Request OTP
                  </button>
                ) : (
                  <button
                    style={{
                      width: "166px",
                      color: "white",
                      backgroundColor: "#055646",
                      borderRadius: "4px",
                      padding: "10px 36px",
                      textAlign: "center",
                      marginTop: "32px",
                    }}
                    onClick={verifyRequest}
                  >
                    Verify OTP
                  </button>
                )}

                <button
                  style={{
                    width: "166px",
                    color: "white",
                    backgroundColor: "grey",
                    borderRadius: "4px",
                    padding: "10px 36px",
                    textAlign: "center",
                    marginLeft: "32px",
                    marginTop: "32px",
                  }}
                  onClick={() => {
                    reset();
                  }}
                >
                  Reset
                </button>
              </div>

              {error && (
                <div style={{ paddingTop: "32px" }}>
                  {
                    <ul>
                      {error.split("/").map((dt) => {
                        return <li style={{ color: "red" }}>{dt}</li>;
                      })}
                    </ul>
                  }
                </div>
              )}
            </div>
          ) : (
            <div style={{ fontSize: "24px", textAlign: "center" }}>
              Category is not set for this Course
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchApplications, dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUploadLearnersModal);
