import React, { useState, useEffect } from "react";
import momentjs from "moment-timezone";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styles from "./pendingOrfailedKaching.scss";
import { getPendingOrFailedUnverifiedKachings } from "../../../actions/general";

const style = {
  position: "absolute",
  top: "10%",
  left: "7%",
  transform: "translate(0%, -8%)",
  maxWidth: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PendingOrFailedKaching = ({ open, closeModal, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [kachings, setKachings] = useState([]);
  const [totalkachings, setTotalKachings] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);

  useEffect(() => {
    start(pageSize, pageNo);
  }, []);

  const start = async (pageSize, pageNo) => {
    setLoading(true);
    const { success, message, ...data } =
      await getPendingOrFailedUnverifiedKachings({ pageSize, pageNo });
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    setKachings(data.kachings);
    setTotalKachings(data.totalkachings);
    setLoading(false);
  };

  const handleChangePage = async (event, newPage) => {
    setPageNo(newPage);
    start(pageSize, newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
    start(parseInt(event.target.value, 10), 0);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="pendingOrfailedKaching">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ fontWeight: "600" }}
              >
                Pending/Failed Kachings
              </Typography>
            </div>

            {kachings.length ? (
              <TableContainer
                style={{ marginBottom: "80px" }}
                className="mainTable"
                component={Paper}
              >
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        S. No.
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Lead Owner
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Mobile
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Course
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Revenue
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Sales Date
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{
                          fontWeight: 600,
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kachings.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.sales_owner_email}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.nam}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            +{row.ccod || row.ccod_unverified}-
                            {row.m || row.m_unverified}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.ep}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.curr} {row.revenue}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {momentjs(
                              new Date(row.sales_date).getTime() + 19800000
                            ).format("lll")}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.activity_type == 1
                              ? "Create"
                              : row.activity_type == 2
                              ? "Edit"
                              : "Delete"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "100px", textAlign: "center" }}
                          >
                            {row.retry_count >= 21 ? "Failed" : "Pending"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={totalkachings}
                  rowsPerPage={pageSize}
                  page={pageNo}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  colSpan={8}
                />
              </TableContainer>
            ) : (
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "center",
                  width: "100%",
                  marginTop: "24px",
                  marginBottom: "80px",
                }}
              >
                No Kachings found
              </div>
            )}

            <div
              className="container-kcv"
              style={{ position: "absolute", right: "32px", bottom: "40px" }}
            >
              <button className="cancel-kcv" onClick={closeModal}>
                Close
              </button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default PendingOrFailedKaching;
