import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "antd";
import Loader from "./Loader";
import { fetchApplications } from "../../actions/applicationFormAction";
import { APPLICATION_STATUS } from "../../constants";
import callicon from "../../images/call.svg";
import envelopeicon from "../../images/envelope.svg";
import { applyPreemptivePromocode } from "../../actions/applicationFormAction";

import currency from "../../currency";

const ApplyPromocodeModal = (props) => {
  const [code, setCode] = useState("");
  const [currTransaction, setCurrTransaction] = useState(null);
  const [allPackages, setAllPackages] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  useEffect(() => {
    setCurrTransaction(props.transaction);
    setAllPackages(props.packages);
    setSelectedCurrency(props.currencySelected);
    console.log({ props });
  }, [props]);

  const closeModal = () => {
    props.handlApplyPromocodeModalToggle(false);
  };

  const applyPromocode = async () => {
    if (!code.trim()) {
      return window.alert("Please enter promocode");
    }
    let fetch_data = {
      a_id: props.applicationDetail.a_id,
      crs_id: props.applicationDetail.crs_id,
      app_id: props.applicationDetail.user_app_id,
    };

    props.applyPreemptivePromocode({
      app_id: props.applicationDetail.user_app_id,
      code: code,
      package_id: currTransaction
        ? currTransaction.updated_package._id
        : allPackages.find((dt) => dt.curr == selectedCurrency)._id,
      fetch_data,
      closeModal: closeModal,
    });
  };
  if (props.appreview.arloading) {
    return <Loader loading={this.props.appreview.arloading} />;
  }
  return (
    <div>
      <Modal
        title="Apply Promocode"
        open={props.open}
        onCancel={closeModal}
        width="700px"
        className="applicationmodal"
      >
        <div>
          <div className="row no-margin" style={{ padding: 24 }}>
            {(currTransaction || selectedCurrency) && allPackages.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <input value={code} onChange={(e) => setCode(e.target.value)} />
                <div
                  style={{
                    marginLeft: "24px",
                    width: "166px",
                    color: "white",
                    backgroundColor: "#055646",
                    borderRadius: "4px",
                    padding: "8px 36px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={applyPromocode}
                >
                  Apply Code
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = {
  applyPreemptivePromocode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyPromocodeModal);
