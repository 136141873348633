import React from "react";
import { connect } from "react-redux";
import { Select, Input, Button } from "antd";
import {
  fetchApplications,
  selectedApplicationStatus,
  deleteUserInfoData,
  initiateApplication,
} from "../../actions/applicationFormAction";
import ViewApplicationModal from "./ViewApplicationModal";
import BulkUploadLearnersModal from "./BulkUploadLearnersModal";
import PastBulkUploadRequestModal from "./PastBulkUploadRequestModal";
import Loader from "./Loader";
import { APPLICATION_STATUS } from "../../constants";
import ViewScholarshipModal from "./ViewScholarshipModal";
import countryCodes from "../../countryCodes";
const { Option } = Select;

class ApplicationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openViewApplicationModal: false,
      searchType: this.props.searchType ? this.props.searchType : "phone",
      searchVal: this.props.searchVal
        ? this.props.searchType == "email" || this.props.searchType == "uid"
          ? this.props.searchVal
          : this.props.searchVal.split("-")[1]
        : "",
      ccod:
        this.props.searchVal && this.props.searchType == "phone"
          ? this.props.searchVal.split("-")[0]
          : "91",
      openViewBulkUploadLearnerModal: false,
      openViewBulkUploadPastRequestModal: false,
    };
  }

  componentDidMount() {
    this.props.deleteUserInfoData();
    //this.props.fetchApplications({crs_pg_id: this.props.appreview.crs_pg_id, app_status : this.props.appreview.app_status,appcurrent_page : this.props.appreview.appcurrent_page});
  }

  showMoreRecords = () => {
    this.props.dispatch(
      this.props.fetchApplications({
        crs_pg_id: this.props.appreview.crs_pg_id._id,
        app_status: this.props.appreview.app_status
          ? this.props.appreview.app_status.status
          : undefined,
        appcurrent_page: this.props.appreview.appcurrent_page + 1,
      })
    );
  };

  handleViewApplicationButton(index, e) {
    this.setState({
      selectedApplication: this.props.appreview.applications[index],
      openViewApplicationModal: true,
    });
  }

  HandleViewScholarshipButton(index, e) {
    this.setState({
      selectedApplication: this.props.appreview.applications[index],
      openScholarshipModal: true,
    });
  }

  handleViewApplicationModalToggle = (val) => {
    let finalSearchValue;
    if (this.state.searchType == "email" || this.state.searchType == "uid") {
      finalSearchValue = this.state.searchVal;
    } else {
      finalSearchValue = `${this.state.ccod}-${this.state.searchVal}`;
    }
    this.setState({
      openViewApplicationModal: val,
    });
    this.props.dispatch(
      this.props.fetchApplications({
        crs_pg_id: this.props.appreview.crs_pg_id._id,
        search_val: finalSearchValue,
        search_type: this.state.searchType,
        app_status: this.props.appreview.app_status
          ? this.props.appreview.app_status.status
          : undefined,
        appcurrent_page: 1,
      })
    );
  };

  handleViewScholarshipModalToggle = (val) => {
    this.setState({
      openScholarshipModal: val,
    });
  };

  handleViewBulkUploadLearnersModalToggle = (val) => {
    this.setState({
      openViewBulkUploadLearnerModal: val,
    });
  };

  handleViewBulkUploadPastRequestModalToggle = (val) => {
    this.setState({
      openViewBulkUploadPastRequestModal: val,
    });
  };

  handleSearchSubmit = () => {
    let finalSearchValue;
    if (this.state.searchType == "email" || this.state.searchType == "uid") {
      finalSearchValue = this.state.searchVal;
    } else {
      finalSearchValue = `${this.state.ccod}-${this.state.searchVal}`;
    }
    this.props.dispatch(
      this.props.handleSubmit(this.state.searchType, finalSearchValue)
    );
    this.props.dispatch(
      this.props.fetchApplications({
        crs_pg_id: this.props.appreview.crs_pg_id._id,
        search_val: finalSearchValue,
        search_type: this.state.searchType,
        app_status: this.props.appreview.app_status
          ? this.props.appreview.app_status.status
          : undefined,
        appcurrent_page: 1,
      })
    );
  };

  handleInitiateApplication = (email, phone) => {
    if (
      !window.confirm(
        "Are you sure you want to initiate the application for the this lead?"
      )
    ) {
      return;
    }
    let finalSearchValue;
    if (this.state.searchType == "email" || this.state.searchType == "uid") {
      finalSearchValue = this.state.searchVal;
    } else {
      finalSearchValue = `${this.state.ccod}-${this.state.searchVal}`;
    }
    this.props.dispatch(
      this.props.initiateApplication({
        email,
        phone,
        crs_pg_id: this.props.appreview.crs_pg_id._id,
        fetch_data: {
          crs_pg_id: this.props.appreview.crs_pg_id._id,
          search_val: finalSearchValue,
          search_type: this.state.searchType,
          app_status: this.props.appreview.app_status
            ? this.props.appreview.app_status.status
            : undefined,
          appcurrent_page: 1,
        },
      })
    );
  };

  checkVisibility = (resource) => {
    if (!this.props.permissions) {
      return true;
    } else {
      if (!this.props.permissions[resource]) {
        return false;
      } else if (this.props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    console.log(this.props);

    const showNoData = (
      <div className="ar-norecord">Oops! No Applications Found</div>
    );

    const showMoreButton = (
      <div>
        <button className="ar-show-more" onClick={this.showMoreRecords}>
          Show More
        </button>
      </div>
    );

    return (
      <div className="app-review">
        {this.state.openViewApplicationModal ? (
          <ViewApplicationModal
            open={this.state.openViewApplicationModal}
            handleViewApplicationModalToggle={
              this.handleViewApplicationModalToggle
            }
            refreshApplication={this.showMoreRecords}
            applicationDetail={this.state.selectedApplication}
          />
        ) : null}
        {this.state.openViewBulkUploadLearnerModal ? (
          <BulkUploadLearnersModal
            open={this.state.openViewBulkUploadLearnerModal}
            handleViewBulkUploadLearnersModalToggle={
              this.handleViewBulkUploadLearnersModalToggle
            }
          />
        ) : null}
        {this.state.openViewBulkUploadPastRequestModal ? (
          <PastBulkUploadRequestModal
            open={this.state.openViewBulkUploadPastRequestModal}
            handleViewBulkUploadPastRequestModalToggle={
              this.handleViewBulkUploadPastRequestModalToggle
            }
          />
        ) : null}
        {/* {this.state.openScholarshipModal ? (
          <ViewScholarshipModal
            open={this.state.openScholarshipModal}
            handleViewScholarshipModalToggle={
              this.handleViewScholarshipModalToggle
            }
            applicationDetail={this.state.selectedApplication}
          />
        ) : null} */}

        {this.props.appreview.crs_pg_id ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginTop: "40px" }}>
                <Select
                  onChange={(e) => {
                    this.setState({
                      searchType: e,
                    });
                  }}
                  value={this.state.searchType}
                  style={{ width: "100px", marginRight: "32px" }}
                >
                  <Option value="phone">Mobile</Option>
                  {/* <Option value="email">Email</Option> */}
                  <Option value="uid">UID</Option>
                </Select>

                {this.state.searchType == "phone" && (
                  <>
                    <Select
                      onChange={(e) => {
                        this.setState({
                          ccod: e,
                        });
                      }}
                      value={this.state.ccod}
                      style={{ width: "120px" }}
                    >
                      {countryCodes.map((cc, idx) => {
                        return (
                          <Option key={idx} value={cc.value}>
                            {cc.display}
                          </Option>
                        );
                      })}
                    </Select>
                    <Input
                      type="number"
                      onChange={(e) => {
                        this.setState({ searchVal: e.target.value });
                      }}
                      value={this.props.searchVal}
                      style={{ width: "180px" }}
                    />
                  </>
                )}
                {this.state.searchType == "email" && (
                  <Input
                    type="text"
                    onChange={(e) => {
                      this.setState({ searchVal: e.target.value });
                    }}
                    value={this.props.searchVal}
                    style={{ width: "300px" }}
                  />
                )}
                {this.state.searchType == "uid" && (
                  <Input
                    type="number"
                    onChange={(e) => {
                      this.setState({ searchVal: e.target.value });
                    }}
                    value={this.props.searchVal}
                    style={{ width: "300px" }}
                  />
                )}
                <Button
                  style={{
                    background: "#055646",
                    color: "white",
                    fontWeight: "bold",
                    marginLeft: "32px",
                  }}
                  onClick={() => {
                    this.handleSearchSubmit();
                  }}
                >
                  Search
                </Button>
              </div>
              {this.checkVisibility("52") && (
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: "40px" }}>
                    <Button
                      style={{
                        background: "#055646",
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: "32px",
                      }}
                      onClick={() => {
                        this.setState({
                          openViewBulkUploadLearnerModal: true,
                        });
                      }}
                    >
                      Bulk Upload Learners
                    </Button>
                  </div>
                  <div style={{ marginTop: "40px" }}>
                    <Button
                      style={{
                        background: "#055646",
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: "32px",
                      }}
                      onClick={() => {
                        this.setState({
                          openViewBulkUploadPastRequestModal: true,
                        });
                      }}
                    >
                      Past Bulk Upload Requests
                    </Button>
                  </div>{" "}
                </div>
              )}
            </div>
            {this.props.appreview.applications && (
              <>
                <div className="ar-header-bar">
                  <div className="ar-total-records">
                    Total Applications: {this.props.appreview.total_app}
                  </div>
                  {this.props.appreview.applications.length > 0
                    ? this.props.appreview.applications.length <
                      this.props.appreview.total_app
                      ? showMoreButton
                      : null
                    : null}
                </div>
                {this.props.appreview.applications.length == 0 &&
                !this.props.appreview.isLead ? (
                  showNoData
                ) : (
                  <>
                    {this.props.appreview.isLead ? (
                      <div
                        style={{
                          marginBottom: "32px",
                          height: "70vh",
                          marginTop: "32px",
                          overflowY: "auto",
                        }}
                      >
                        <table
                          className="hide-sm table table-container"
                          style={{ margin: "0" }}
                        >
                          <thead className="ar-table-header">
                            <tr>
                              <th scope="col" className="container-td">
                                User Name
                              </th>
                              <th scope="col" className="container-td">
                                Mobile No
                              </th>
                              <th scope="col" className="container-td">
                                Email
                              </th>
                              <th scope="col" className="container-td">
                                Application Status
                              </th>
                              <th scope="col" style={{ width: "12%" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={"cf-row" + (0 % 2).toString()}>
                              <td className="container-td">
                                {this.props.appreview.lead.FirstName}
                                {this.props.appreview.lead.LastName
                                  ? " " + this.props.appreview.lead.LastName
                                  : null}
                              </td>
                              <td className="container-td">
                                {this.props.appreview.lead.Phone || "-------"}
                              </td>
                              <td className="container-td">
                                {this.props.appreview.lead.EmailAddress ||
                                  "-------"}
                              </td>
                              <td className="container-td">Not Started</td>
                              <td
                                className=""
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <button
                                  className="ar-vw-app"
                                  style={{ width: "40%" }}
                                  onClick={(e) =>
                                    this.handleInitiateApplication(
                                      this.props.appreview.lead.EmailAddress,
                                      this.props.appreview.lead.Phone
                                    )
                                  }
                                >
                                  Initiate Application
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="show-sm" style={{ marginTop: 24 }}>
                          <div className="ar-sm-table-div">
                            <div style={{ padding: 16 }}>
                              <div className="ar-sm-u-name">
                                {this.props.appreview.lead.FirstName}
                                {this.props.appreview.lead.LastName
                                  ? " " + this.props.appreview.lead.LastName
                                  : null}
                              </div>
                              <div className="ar-sm-crs-name">
                                {this.props.appreview.lead.Phone || "-------"}
                              </div>
                              <div className="ar-sm-sub-tim">Not Started</div>

                              <button
                                className="ar-vw-app"
                                onClick={(e) =>
                                  this.handleInitiateApplication(
                                    this.props.appreview.lead.EmailAddress,
                                    this.props.appreview.lead.Phone
                                  )
                                }
                              >
                                Initiate Application
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginBottom: "32px",
                          height: "70vh",
                          marginTop: "32px",
                          overflowY: "auto",
                        }}
                      >
                        <table
                          className="hide-sm table table-container"
                          style={{ margin: "0" }}
                        >
                          <thead className="ar-table-header">
                            <tr>
                              <th scope="col" className="container-td">
                                User Name
                              </th>
                              <th scope="col" className="container-td">
                                UID
                              </th>
                              <th scope="col" className="container-td">
                                Mobile No
                              </th>
                              {/* <th scope="col" className="container-td">
                                Pegasus Email
                              </th> */}
                              <th scope="col" className="container-td">
                                Application Email
                              </th>
                              <th scope="col" className="container-td">
                                Application Status
                              </th>
                              <th scope="col" className="container-td">
                                Submission Date
                              </th>
                              <th scope="col" style={{ width: "6%" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.appreview.applications.map(
                              (application, index) => (
                                <tr
                                  className={"cf-row" + (index % 2).toString()}
                                  key={application.app_id + index}
                                >
                                  <td className="container-td">
                                    {application.u_name || "-------"}
                                  </td>
                                  <td className="container-td">
                                    {application.uid || "-------"}
                                  </td>
                                  <td className="container-td">
                                    {application.p_no || "-------"}
                                  </td>
                                  {/* <td className="container-td">
                                    {application.email || "-------"}
                                  </td> */}
                                  <td className="container-td">
                                    {application.applicationEmail ||
                                      application.email ||
                                      "-------"}
                                  </td>
                                  <td className="container-td">
                                    {APPLICATION_STATUS[application.stts] ||
                                      "-------"}
                                  </td>
                                  <td className="container-td">
                                    {application.tim || "-------"}
                                  </td>
                                  <td
                                    className=""
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <button
                                      className="ar-vw-app"
                                      style={{ width: "80%" }}
                                      onClick={(e) =>
                                        this.handleViewApplicationButton(
                                          index,
                                          e
                                        )
                                      }
                                    >
                                      Review Application
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <div className="show-sm" style={{ marginTop: 24 }}>
                          {this.props.appreview.applications.map(
                            (application, index) => (
                              <div
                                className="ar-sm-table-div"
                                key={application.app_id + index}
                              >
                                <div style={{ padding: 16 }}>
                                  <div className="ar-sm-u-name">
                                    {application.u_name
                                      ? application.u_name
                                      : "-------"}
                                  </div>
                                  <div className="ar-sm-crs-name">
                                    {application.p_no
                                      ? application.p_no
                                      : "-------"}
                                  </div>
                                  {/* <div className="ar-sm-crs-name">
                                    {application.email
                                      ? application.email + " (Pegasus Email)"
                                      : "-------"}
                                  </div> */}
                                  <div className="ar-sm-crs-name">
                                    {application.applicationEmail
                                      ? application.applicationEmail
                                      : application.email || "-------"}
                                  </div>
                                  <div className="ar-sm-crs-name">
                                    {APPLICATION_STATUS[application.stts]
                                      ? APPLICATION_STATUS[application.stts]
                                      : "-------"}
                                  </div>
                                  <div className="ar-sm-sub-tim">
                                    {application.tim
                                      ? "Submitted: " + application.tim
                                      : "-------"}
                                  </div>
                                  <button
                                    className="ar-vw-app"
                                    onClick={(e) =>
                                      this.handleViewApplicationButton(index, e)
                                    }
                                  >
                                    Review Application
                                  </button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {this.state.searchType &&
                          this.state.searchType == "email" &&
                          this.state.searchVal &&
                          this.props.appreview.applications.length > 1 && (
                            <div style={{ marginTop: "20px" }}>
                              Note: The advisor should consider the application
                              which contains the student's correct mobile
                              number.
                            </div>
                          )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div className="ar-header-bar-course">
            <div className="ar-total-records">Please select a course !!!</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplications,
    selectedApplicationStatus,
    deleteUserInfoData,
    initiateApplication,
    handleSubmit: (searchType, searchVal) => (dispatch) => {
      return dispatch({
        type: "UPDATE_APPLICARION_SEARCH_DATA",
        payload: {
          searchType: searchType,
          searchVal: searchVal,
        },
      });
    },
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTable);
