import styles from "./ViewPaymentPacket.scss";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { fetchAllTransactionPacket } from "../../../actions/general";

const style = {
  position: "absolute",
  top: "10%",
  left: "35%",
  transform: "translate(0%, -8%)",
  maxWidth: "35%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewPaymentPacket = ({ open, closeModal, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [paymentPacket, setPaymentPacket] = useState({});

  useEffect(() => {
    getPacket();
  }, []);

  const getPacket = async () => {
    setLoading(true);
    let { success, message, ...data } = await fetchAllTransactionPacket({
      allTransaction_id: props.paymentId,
      gateway: props.gateway,
    });
    setLoading(false);
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    console.log({ allTransactionPacket: data.allTransactionPacket });
    setPaymentPacket(data.allTransactionPacket);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="ViewPaymentPacket">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ fontWeight: "600" }}
              >
                Raw Payment Packet
              </Typography>
            </div>
            <div>
              {Object.keys(paymentPacket).length > 0 ? (
                paymentPacket.gateway == 1 || paymentPacket.gateway == 3 ? (
                  <pre>
                    {JSON.stringify(paymentPacket.razorpayPacket, null, 2)}
                  </pre>
                ) : paymentPacket.gateway == 2 ? (
                  <pre>
                    {JSON.stringify(paymentPacket.paytmPacket, null, 2)}
                  </pre>
                ) : (
                  <pre>
                    {JSON.stringify(paymentPacket.stripePacket, null, 2)}
                  </pre>
                )
              ) : null}
            </div>
            <div className="container">
              <button className="cancel" onClick={closeModal}>
                Close
              </button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ViewPaymentPacket;
