import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import countryCodes from "../../../countryCodes";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./addOrEditKaching.scss";
import {
  getLeadFromMobile,
  fetchCourseFeeStructures,
  requestUnverifiedKachingOTP,
  resendUnverifiedKachingOTP,
  verifyUnverifiedKachingOTP,
  updateUnverifiedKachingOTP,
} from "../../../actions/general";

const style = {
  position: "absolute",
  top: "10%",
  left: "30%",
  transform: "translate(0%, -8%)",
  width: "660px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddOrEditKaching = ({ open, closeModal, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [ccod, setCcod] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [leadFetched, setLeadFetched] = useState(false);
  const [leadDetails, setLeadDetails] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState();
  const [revenue, setRevenue] = useState(props.revenue || "");
  const [date, setDate] = useState(
    props.sales_date ? moment(props.sales_date).format("YYYY-MM-DDTHH:mm") : ""
  );
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpRequestId, setOtpRequestId] = useState("");
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const Ref = useRef(null);
  const [kachingId, setKachingId] = useState(props.kaching_id);

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    if (props.edit) {
      const res = await getLeadFromMobile({
        mobile: props.m || props.m_unverified,
        ccod: props.ccod || props.ccod_unverified,
      });
      setLeadDetails(res.lead);
      setSelectedCourse(props.courses.find((dt) => dt._id == props.crs_pg_id));
      const response = await fetchCourseFeeStructures(props.crs_pg_id);
      setPackages(response.coursePackages);
      setSelectedPackage(
        response.coursePackages.find((dt) => dt._id == props.pkg_id)
      );
      setLeadFetched(true);
    }
  };

  let selectCcod = (ev, ccod) => {
    console.log({ ev, ccod });
    setCcod({ ...ccod });
  };

  let getLead = async () => {
    setLoading(true);
    const { success, message, ...res } = await getLeadFromMobile({
      mobile,
      ccod: ccod.value,
    });
    if (!success) {
      setLoading(false);
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    setLeadDetails(res.lead);
    setLeadFetched(true);
    setSelectedCourse(null);
    setPackages([]);
    setSelectedPackage();
    setRevenue("");
    setDate("");
    setOtpSent(false);
    setOtp("");
    setOtpRequestId("");
    setShowResendOtp(false);
    setTimer("00:00");
    setLoading(false);
  };

  const handleCourseChange = async (course) => {
    setSelectedCourse(course);
    const response = await fetchCourseFeeStructures(course._id);
    setPackages(response.coursePackages);
  };

  const requestOTP = async () => {
    if (selectedPackage)
      if (revenue == undefined || revenue == "") {
        return window.alert("Please enter revenue");
      }
    if (date == undefined || date == "") {
      return window.alert("Please enter sales date");
    }
    if (
      revenue < 0 ||
      revenue >
        selectedPackage.price +
          (selectedPackage.app_fees || 0) +
          (selectedPackage.reg_price || 0)
    ) {
      window.alert(
        `Revenue should range from 1 to ${
          selectedPackage.price +
          (selectedPackage.app_fees || 0) +
          (selectedPackage.reg_price || 0)
        }`
      );
      return;
    }
    if (moment(date).valueOf() > new Date().getTime()) {
      return window.alert("Please provide a past date-time");
    }
    let data = {
      crs_pg_id: selectedCourse._id,
      package_id: selectedPackage._id,
      prospect_id: leadDetails.ProspectID,
      revenue,
      sales_date: moment(date).valueOf(),
    };

    setLoading(true);
    const { success, message, ...res } = await requestUnverifiedKachingOTP(
      data
    );
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    setShowResendOtp(false);
    clearTimer(getDeadTime());
    setOtpRequestId(res.otp_request_id);
    setOtpSent(true);
    setLoading(false);
  };

  const verifyOTP = async () => {
    if (!otp) {
      return window.alert("Please enter an otp.");
    }
    setLoading(true);
    const { success, message, ...res } = await verifyUnverifiedKachingOTP({
      otp_request_id: otpRequestId,
      otp_code: otp,
    });
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    } else {
      window.alert(message);
    }
    setLoading(false);
    closeModal();
  };

  const resendOTP = async () => {
    setLoading(true);
    const { success, message, ...res } = await resendUnverifiedKachingOTP({
      otp_request_id: otpRequestId,
    });
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    setShowResendOtp(false);
    setOtpRequestId(res.otp_request_id);
    clearTimer(getDeadTime());
    setOtpSent(true);
    setLoading(false);
  };

  const editKaching = async () => {
    if (
      revenue < 0 ||
      revenue >
        selectedPackage.price +
          (selectedPackage.app_fees || 0) +
          (selectedPackage.reg_price || 0)
    ) {
      window.alert(
        `Revenue should range from 1 to ${
          selectedPackage.price +
          (selectedPackage.app_fees || 0) +
          (selectedPackage.reg_price || 0)
        }`
      );
      return;
    }
    if (moment(date).valueOf() > new Date().getTime()) {
      return window.alert("Please provide a past date-time");
    }
    let data = {
      kaching_id: kachingId,
      revenue,
      sales_date: moment(date).valueOf(),
    };

    setLoading(true);
    const { success, message, ...res } = await updateUnverifiedKachingOTP(data);
    console.log({ success, message, res });
    if (!success) {
      setLoading(false);
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    } else {
      window.alert(message);
    }
    setLoading(false);
    closeModal();
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setShowResendOtp(true);
      if (Ref.current) clearInterval(Ref.current);
    }
  };

  const clearTimer = (e) => {
    setTimer("2:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="addOrEditKaching">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{ fontWeight: "600" }}
              >
                {props.edit ? "Edit" : "Add"} Kaching
              </Typography>
            </div>
            {!props.edit && (
              <div style={{ display: "flex", marginTop: "20px" }}>
                <Autocomplete
                  multiple={false}
                  disablePortal
                  disableClearable
                  sx={{ width: 150 }}
                  autoHighlight
                  value={ccod}
                  onChange={selectCcod}
                  getOptionLabel={(ccode) => {
                    return ccode?.display || "";
                  }}
                  id="ccodeSelector"
                  options={countryCodes}
                  disabled={props.edit || otpSent}
                  style={{ marginRight: "20px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label="Select a country code"
                        {...params}
                        variant="outlined"
                      />
                    );
                  }}
                />
                <TextField
                  id="mobile"
                  value={mobile}
                  onChange={(e) =>
                    e.target.value.length < 15
                      ? setMobile(e.target.value)
                      : null
                  }
                  style={{ width: "180px", marginRight: "20px" }}
                  disabled={props.edit || otpSent}
                  label="Mobile Number"
                  variant="outlined"
                />
                <button
                  style={{
                    width: "180px",
                    color: "white",
                    backgroundColor: "#055646",
                    borderRadius: "4px",
                    padding: "6px 20px",
                    textAlign: "center",
                    marginRight: "16px",
                    height: "56px",
                  }}
                  disabled={props.edit || otpSent}
                  onClick={getLead}
                >
                  Fetch Lead Details
                </button>
              </div>
            )}
            {leadFetched && (
              <>
                <div style={{ color: "black" }}>
                  <div style={{ margin: "28px 0 32px", fontSize: "16px" }}>
                    <div>
                      <span style={{ fontWeight: "600" }}>Lead ID</span> :{" "}
                      {props.edit ? props.prospect_id : leadDetails.ProspectID}
                    </div>
                    {props.edit && (
                      <div style={{ marginTop: "12px" }} className="kc-text">
                        <span style={{ fontWeight: "600" }}>Mobile Number</span>{" "}
                        : +{props.ccod || props.ccod_unverified}-
                        {props.m || props.m_unverified}
                      </div>
                    )}
                    <div style={{ margin: "12px 0" }} className="kc-text">
                      <span style={{ fontWeight: "600" }}>Email</span> :{" "}
                      {props.edit ? props.ep : leadDetails.EmailAddress}
                    </div>
                    <div>
                      <span style={{ fontWeight: "600" }}>Name</span> :{" "}
                      {props.edit
                        ? props.nam
                        : leadDetails.FirstName +
                          (leadDetails.LastName
                            ? ` ${leadDetails.LastName}`
                            : "")}
                    </div>
                  </div>

                  <div style={{ display: "flex", marginTop: "20px" }}>
                    <Autocomplete
                      multiple={false}
                      disablePortal
                      disableClearable
                      sx={{ width: 350 }}
                      autoHighlight
                      value={selectedCourse}
                      disabled={props.edit || otpSent}
                      onChange={(ev, course) => handleCourseChange(course)}
                      getOptionLabel={(course) => {
                        return course?.name || "";
                      }}
                      id="courseSelector"
                      style={{ width: "300px", marginRight: "20px" }}
                      options={props.courses}
                      renderInput={(params) => {
                        return (
                          <TextField
                            label="Select a Course"
                            {...params}
                            variant="outlined"
                          />
                        );
                      }}
                    />
                    {packages && packages.length ? (
                      <Autocomplete
                        disablePortal
                        disableClearable
                        autoHighlight
                        onChange={(e, val) => {
                          setSelectedPackage(val);
                        }}
                        value={selectedPackage}
                        options={packages || []}
                        getOptionLabel={(crs) => {
                          return crs.curr;
                        }}
                        style={{ width: "180px" }}
                        disabled={props.edit || otpSent}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Select Currency"
                              variant="outlined"
                              className="input-field"
                              style={{ padding: 0 }}
                            />
                          );
                        }}
                      />
                    ) : null}
                  </div>

                  {packages && packages.length && selectedPackage ? (
                    <div style={{ marginTop: "28px" }}>
                      <TextField
                        variant="outlined"
                        type="number"
                        placeholder="Revenue"
                        value={revenue}
                        disabled={otpSent}
                        onChange={(e) => setRevenue(e.target.value)}
                        style={{ width: "300px", marginRight: "20px" }}
                      />
                      <input
                        type="datetime-local"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        max={moment().format("YYYY-MM-DDTHH:mm")}
                        disabled={otpSent}
                        className="kcv_dateInput"
                        style={{ width: "180px" }}
                      ></input>
                    </div>
                  ) : null}
                </div>

                {selectedPackage && otpSent && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      style={{ width: "300px", marginRight: "20px" }}
                    />
                    {!showResendOtp ? (
                      <span style={{ color: "#055646" }}>
                        Resend OTP in {timer}
                      </span>
                    ) : (
                      <button
                        style={{
                          width: "140px",
                          color: "white",
                          backgroundColor: "#055646",
                          borderRadius: "4px",
                          padding: "6px 20px",
                          height: "40px",
                          textAlign: "center",
                        }}
                        onClick={resendOTP}
                      >
                        Resend OTP
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
            <div
              style={{ position: "absolute", right: "32px", bottom: "40px" }}
              className="container-kcv"
            >
              {selectedPackage ? (
                otpSent ? (
                  <button className="btn-kcv" onClick={verifyOTP}>
                    Verify OTP
                  </button>
                ) : props.edit ? (
                  <button className="btn-kcv" onClick={editKaching}>
                    Save
                  </button>
                ) : (
                  <button className="btn-kcv" onClick={requestOTP}>
                    Request OTP
                  </button>
                )
              ) : null}
              <button
                style={{ marginLeft: "20px" }}
                className="cancel-kcv"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddOrEditKaching;
