import moment from "moment";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import {
  getGroupDetails,
  createPromo,
  createSpecialPromo,
  resetSpecialPromoState,
  resetPromoState,
  requestDiscountOTP,
  verifyDiscountOTP,
} from "../../actions/promoActions";
import AddFormSpecial from "./components/addForm_special";
import {
  Statistic,
  Badge,
  Card,
  Form,
  Input,
  Spin,
  Layout,
  Tooltip,
  Breadcrumb,
  Icon,
  DatePicker,
  Tag,
  Row,
  Col,
  notification,
  Button,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Countdown = Statistic.Countdown;

// @connect((store) => {
//   console.log(store);
//   return {
//     promo: store.promo.data,
//     spsavedpromo : store.promo.spsavedpromo,
//     showsavedpromo: store.promo.showsavedpromo,
//     isSaved : store.promo.isSaved,
//     loading : store.promo.loading
//   };
// })

let AddSpecialPromocodeForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  let copyToClipboard = (e) => {
    var textField = document.createElement("textarea");
    var code = e.target.innerHTML;
    textField.innerText = code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    e.target.focus();
    copiedToClipboardNotify(code);
  };

  let copiedToClipboardNotify = (code) => {
    var msg = code + " Successfully Copied to your Clipboard";
    notification["success"]({
      message: "Successfully Copied",
      description: msg,
    });
  };

  useEffect(() => {
    console.log({ props });
  }, []);
  useEffect(() => {
    props.dispatch(resetSpecialPromoState());
  }, [location]);

  let handleNegetiveNumber = (e) => {
    if (e && e.target && e.target.value) {
      e.target.value = Math.floor(e.target.value);
      e.target.value = Math.abs(e.target.value);
    }
  };

  let checkPhoneNumber = (rule, value, callback) => {
    if (isNaN(value) == true) {
      callback("Invalid phone number");
    } else if (value.toString().length == 10) {
      callback();
    } else {
      callback("Phone number must be of 10 digits");
    }
  };

  let submitSpecialPromoCode = (
    data,
    specialPromForm,
    approved,
    setLoading
  ) => {
    console.log("submitting promo code");
    data.exp = Number(moment(data.exp).format("x"));
    setLoading(true);
    if (approved) {
      props.dispatch(createSpecialPromo(data, specialPromForm, setLoading));
    } else {
      props.dispatch(requestDiscountOTP(data, setLoading));
    }
  };

  let verifySpecialPromoCode = (data, specialPromForm) => {
    console.log("verifying promo code otp");
    props.dispatch(verifyDiscountOTP(data, specialPromForm));
  };

  console.log(props, "++++++++++++++++++++++++++++++");
  var isSaved = props.showsavedpromo;
  var savedData = isSaved ? props.spsavedpromo : {};

  const { Content } = Layout;
  return (
    // Only show error after a field is touched.
    <div>
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item onClick={() => navigate("/")}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/promocode")}>
          Promocode
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate("/promocode/specialpromocode")}
        >
          Special Promocode
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate("/promocode/specialpromocode/add")}
        >
          Add
        </Breadcrumb.Item>
      </Breadcrumb>

      <Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 400 }}
      >
        <AddFormSpecial
          promo={{}}
          data={props.promo}
          callback={submitSpecialPromoCode}
          verifyDiscountOTP={verifySpecialPromoCode}
        />
      </Content>

      <div className="show-specialpromocode-container">
        {isSaved ? (
          <div>
            <center>
              <Card
                className="special-promocode-show-card just-created"
                title="Promocode Detail"
                bordered={true}
              >
                <p className="specialpromocode-text">
                  <span onClick={copyToClipboard}>{savedData.code}</span>
                </p>
                <Countdown
                  className="CountDownBadge"
                  value={savedData.expirydate}
                  format="HH:mm:ss"
                />
                <p className="special-promocode-detail">
                  Phone No: <strong>{savedData.more.pno}</strong>
                </p>
                {(savedData.apply_type == 2 || savedData.apply_type == "2") &&
                savedData.more &&
                savedData.more.course_name ? (
                  <div>
                    <p className="special-promocode-detail">
                      Type: <strong>Course Specific</strong>
                    </p>
                    <p className="special-promocode-detail">
                      Course Name: <strong>{savedData.more.course_name}</strong>
                    </p>
                  </div>
                ) : (
                  <p className="special-promocode-detail">
                    Type: <strong>All Course</strong>
                  </p>
                )}
              </Card>
            </center>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

// const AddSpecialPromocode = Form.create()(AddSpecialPromocodeForm);

const mapStateToProps = (state) => {
  console.log(state);
  return {
    promo: state.promo.data,
    spsavedpromo: state.promo.spsavedpromo,
    showsavedpromo: state.promo.showsavedpromo,
    isSaved: state.promo.isSaved,
    loading: state.promo.loading,
  };
};
export default connect(mapStateToProps)(AddSpecialPromocodeForm);
// export default AddSpecialPromocode;
