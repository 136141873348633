var _INDEX = 3;
console.log({
  "process.env.REACT_APP_NODE_ENV": process.env.REACT_APP_NODE_ENV,
});
if (process.env.REACT_APP_NODE_ENV == "local") {
  _INDEX = 0;
}
if (process.env.REACT_APP_NODE_ENV == "testing") {
  _INDEX = 1;
}
if (process.env.REACT_APP_NODE_ENV == "staging") {
  _INDEX = 2;
}

let _API_ENDPOINT = [
  "http://localhost:3000",
  "https://apiht.pegasust.imarticus.org",
  "https://apihs.pegasuss.imarticus.org",
  "https://apih.pegasus.imarticus.org",
][_INDEX];

let _QUIZ_API_ENDPOINT = [
  "http://localhost:3008",
  "https://examt.pegasust.imarticus.org",
  "https://exams.pegasuss.imarticus.org",
  "https://exam.pegasus.imarticus.org",
][_INDEX];

let _PAYMENT_ENDPOINT = [
  "http://localhost:3511",
  "https://pegasust.imarticus.org/payments",
  "https://pegasuss.imarticus.org/payments",
  "https://pegasus.imarticus.org/payments",
][_INDEX];
let _VIDEO_API_ENDPOINT = [
  "http://localhost:3000",
  "https://videot.pegasust.imarticus.org",
  "https://videos.pegasuss.imarticus.org",
  "https://video.pegasus.imarticus.org",
][_INDEX];

let _DOMAIN = [
  "localhost",
  ".imarticus.org",
  ".imarticus.org",
  ".imarticus.org",
][_INDEX];

export const API_ENDPOINT = _API_ENDPOINT;
export const QUIZ_API_ENDPOINT = _QUIZ_API_ENDPOINT;
export const VIDEO_API_ENDPOINT = _VIDEO_API_ENDPOINT;
export const DOMAIN = _DOMAIN;
export const PATH = "/";
export const PAYMENT_ENDPOINT = _PAYMENT_ENDPOINT;

export const AUTHENTICATION_COOKIES = [
  "at",
  "rt",
  "secret",
  "aid",
  "pid",
  "isLoggedIn",
  "crs_tkn",
  "cid",
  "uid",
  "s_id",
  "user",
];
export const countryCode = [
  "91",
  "1",
  "7",
  "20",
  "27",
  "30",
  "31",
  "32",
  "33",
  "34",
  "36",
  "39",
  "40",
  "41",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "81",
  "82",
  "84",
  "86",
  "90",
  "92",
  "93",
  "94",
  "95",
  "98",
  "211",
  "212",
  "213",
  "216",
  "218",
  "220",
  "221",
  "222",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "230",
  "231",
  "232",
  "233",
  "234",
  "235",
  "236",
  "237",
  "238",
  "239",
  "240",
  "241",
  "242",
  "243",
  "244",
  "245",
  "246",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "255",
  "256",
  "257",
  "258",
  "260",
  "261",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "290",
  "291",
  "297",
  "298",
  "299",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "370",
  "371",
  "372",
  "373",
  "374",
  "375",
  "376",
  "377",
  "378",
  "379",
  "380",
  "381",
  "382",
  "385",
  "386",
  "387",
  "389",
  "420",
  "421",
  "423",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "225",
  "590",
  "591",
  "592",
  "593",
  "594",
  "595",
  "596",
  "597",
  "598",
  "599",
  "670",
  "672",
  "673",
  "674",
  "675",
  "676",
  "677",
  "678",
  "679",
  "680",
  "681",
  "682",
  "683",
  "685",
  "686",
  "687",
  "688",
  "689",
  "690",
  "691",
  "692",
  "850",
  "852",
  "853",
  "855",
  "856",
  "870",
  "880",
  "886",
  "960",
  "961",
  "962",
  "963",
  "964",
  "965",
  "966",
  "967",
  "968",
  "970",
  "971",
  "972",
  "973",
  "974",
  "975",
  "976",
  "977",
  "992",
  "993",
  "994",
  "995",
  "996",
  "998",
];

export const country_code_with_ISO = {
  BD: "880",
  BE: "32",
  BF: "226",
  BG: "359",
  BA: "387",
  BB: "1",
  WF: "681",
  BL: "590",
  BM: "1",
  BN: "673",
  BO: "591",
  BH: "973",
  BI: "257",
  BJ: "229",
  BT: "975",
  JM: "1",
  BV: "",
  BW: "267",
  WS: "685",
  BQ: "599",
  BR: "55",
  BS: "1",
  JE: "44",
  BY: "375",
  BZ: "501",
  RU: "7",
  RW: "250",
  RS: "381",
  TL: "670",
  RE: "262",
  TM: "993",
  TJ: "992",
  RO: "40",
  TK: "690",
  GW: "245",
  GU: "1",
  GT: "502",
  GS: "",
  GR: "30",
  GQ: "240",
  GP: "590",
  JP: "81",
  GY: "592",
  GG: "44",
  GF: "594",
  GE: "995",
  GD: "1",
  GB: "44",
  GA: "241",
  SV: "503",
  GN: "224",
  GM: "220",
  GL: "299",
  GI: "350",
  GH: "233",
  OM: "968",
  TN: "216",
  JO: "962",
  HR: "385",
  HT: "509",
  CI: "225",
  HU: "36",
  HK: "852",
  HN: "504",
  VE: "58",
  PR: "1",
  PS: "970",
  PW: "680",
  PT: "351",
  SJ: "47",
  PY: "595",
  IQ: "964",
  PA: "507",
  PF: "689",
  PG: "675",
  PE: "51",
  PK: "92",
  PH: "63",
  PN: "870",
  PL: "48",
  PM: "508",
  ZM: "260",
  EH: "212",
  EE: "372",
  EG: "20",
  ZA: "27",
  EC: "593",
  IT: "39",
  VN: "84",
  SB: "677",
  ET: "251",
  SO: "252",
  ZW: "263",
  SA: "966",
  ES: "34",
  ER: "291",
  ME: "382",
  MD: "373",
  MG: "261",
  MF: "590",
  MA: "212",
  MC: "377",
  UZ: "998",
  MM: "95",
  ML: "223",
  MO: "853",
  MN: "976",
  MH: "692",
  MK: "389",
  MU: "230",
  MT: "356",
  MW: "265",
  MV: "960",
  MQ: "596",
  MP: "1",
  MS: "1",
  MR: "222",
  IM: "44",
  UG: "256",
  TZ: "255",
  MY: "60",
  MX: "52",
  IL: "972",
  FR: "33",
  IO: "246",
  SH: "290",
  FI: "358",
  FJ: "679",
  FK: "500",
  FM: "691",
  FO: "298",
  NI: "505",
  NL: "31",
  NO: "47",
  NA: "264",
  VU: "678",
  NC: "687",
  NE: "227",
  NF: "672",
  NG: "234",
  NZ: "64",
  NP: "977",
  NR: "674",
  NU: "683",
  CK: "682",
  XK: "",
  CI: "225",
  CH: "41",
  CO: "57",
  CN: "86",
  CM: "237",
  CL: "56",
  CC: "61",
  CA: "1",
  CG: "242",
  CF: "236",
  CD: "243",
  CZ: "420",
  CY: "357",
  CX: "61",
  CR: "506",
  CW: "599",
  CV: "238",
  CU: "53",
  SZ: "268",
  SY: "963",
  SX: "599",
  KG: "996",
  KE: "254",
  SS: "211",
  SR: "597",
  KI: "686",
  KH: "855",
  KN: "1",
  KM: "269",
  ST: "239",
  SK: "421",
  KR: "82",
  SI: "386",
  KP: "850",
  KW: "965",
  SN: "221",
  SM: "378",
  SL: "232",
  SC: "248",
  KZ: "7",
  KY: "1",
  SG: "65",
  SE: "46",
  SD: "249",
  DO: "1",
  DM: "1",
  DJ: "253",
  DK: "45",
  VG: "1",
  DE: "49",
  YE: "967",
  DZ: "213",
  US: "1",
  UY: "598",
  YT: "262",
  UM: "1",
  LB: "961",
  LC: "1",
  LA: "856",
  TV: "688",
  TW: "886",
  TT: "1",
  TR: "90",
  LK: "94",
  LI: "423",
  LV: "371",
  TO: "676",
  LT: "370",
  LU: "352",
  LR: "231",
  LS: "266",
  TH: "66",
  TF: "",
  TG: "228",
  TD: "235",
  TC: "1",
  LY: "218",
  VA: "379",
  VC: "1",
  AE: "971",
  AD: "376",
  AG: "1",
  AF: "93",
  AI: "1",
  VI: "1",
  IS: "354",
  IR: "98",
  AM: "374",
  AL: "355",
  AO: "244",
  AQ: "",
  AS: "1",
  AR: "54",
  AU: "61",
  AT: "43",
  AW: "297",
  IN: "91",
  AX: "358",
  AZ: "994",
  IE: "353",
  ID: "62",
  UA: "380",
  QA: "974",
  MZ: "258",
};

export const APPLICATION_STATUS = {
  0: "Not Filled",
  1: "Pending",
  2: "Paid",
  3: "Approved",
  4: "Rejected",
  5: "Expired",
};

export const BTN_APPLICATION_STATUS = {
  0: "Not Filled",
  1: "Pending",
  2: "Paid",
  3: "Approve",
  4: "Reject",
  5: "Expire",
  6: "Request OTP",
};
